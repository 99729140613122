
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index6XuUWXpkNpCZxI_45qeEy0r3DViMbx9s2jATL3bwa_M5wMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/index.vue?macro=true";
import { default as indexPSHvZTFI8way8SAJTO98XiIfzXsUN_45EhoX9PY9oNdCwMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as start1gxUkuN8yINh4jyA0PtKwcjaZzNJqkscR6uSzGY_xHkMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startamdq6JWD1A5RXX_xVU_45Y_45mZlhQg1KIgEL_VdH5nnD5cMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutiUm_0tr4l8qBiTJrbmCagx6BiS0AzRBNoUEE40M4ugQMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as ko8v1y_fINlinlvZ0FP_45Cz0UNRnfEJFz9dWoK3YK4ud_EMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationBayc2tAZsTqa24WXOxHXNil6ar6zMZL2bs3tfpQfz_45cMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationzZGgcRyuMXAqV_xjN2GMkMnKZISeUzFtlbOu_L1Z8KYMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerU45MEbIsFJbmuwNE4TmMyaDUfx6lMKGGeCU5Emg6QEwMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyer8NBFzRbFsu1_45mAJKKtaSkyEbvK0aLUi99PPvRUaBFY0Meta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentk6qOvBpEbP8nnk2yptjrX0tNws0Gssou6ffUengKsPoMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengetcmNMQgdq5WAHMfWZq9wDyGKWFA8KWZeyw_HQ43lmKIMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentaxQNi0iVVTLynteDtiFx3fu1MjZ_458Yvg3D8_456l1tq6IMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta } from "/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: index6XuUWXpkNpCZxI_45qeEy0r3DViMbx9s2jATL3bwa_M5wMeta?.name ?? "index",
    path: index6XuUWXpkNpCZxI_45qeEy0r3DViMbx9s2jATL3bwa_M5wMeta?.path ?? "/",
    props: index6XuUWXpkNpCZxI_45qeEy0r3DViMbx9s2jATL3bwa_M5wMeta?.props ?? false,
    meta: index6XuUWXpkNpCZxI_45qeEy0r3DViMbx9s2jATL3bwa_M5wMeta || {},
    alias: index6XuUWXpkNpCZxI_45qeEy0r3DViMbx9s2jATL3bwa_M5wMeta?.alias || [],
    redirect: index6XuUWXpkNpCZxI_45qeEy0r3DViMbx9s2jATL3bwa_M5wMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/index.vue")
  },
  {
    name: indexPSHvZTFI8way8SAJTO98XiIfzXsUN_45EhoX9PY9oNdCwMeta?.name ?? "lang",
    path: indexPSHvZTFI8way8SAJTO98XiIfzXsUN_45EhoX9PY9oNdCwMeta?.path ?? "/:lang?",
    props: indexPSHvZTFI8way8SAJTO98XiIfzXsUN_45EhoX9PY9oNdCwMeta?.props ?? false,
    meta: indexPSHvZTFI8way8SAJTO98XiIfzXsUN_45EhoX9PY9oNdCwMeta || {},
    alias: indexPSHvZTFI8way8SAJTO98XiIfzXsUN_45EhoX9PY9oNdCwMeta?.alias || [],
    redirect: indexPSHvZTFI8way8SAJTO98XiIfzXsUN_45EhoX9PY9oNdCwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: start1gxUkuN8yINh4jyA0PtKwcjaZzNJqkscR6uSzGY_xHkMeta?.name ?? "lang-voucher-start",
    path: start1gxUkuN8yINh4jyA0PtKwcjaZzNJqkscR6uSzGY_xHkMeta?.path ?? "/:lang?/voucher/start",
    props: start1gxUkuN8yINh4jyA0PtKwcjaZzNJqkscR6uSzGY_xHkMeta?.props ?? false,
    meta: start1gxUkuN8yINh4jyA0PtKwcjaZzNJqkscR6uSzGY_xHkMeta || {},
    alias: start1gxUkuN8yINh4jyA0PtKwcjaZzNJqkscR6uSzGY_xHkMeta?.alias || [],
    redirect: start1gxUkuN8yINh4jyA0PtKwcjaZzNJqkscR6uSzGY_xHkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startamdq6JWD1A5RXX_xVU_45Y_45mZlhQg1KIgEL_VdH5nnD5cMeta?.name ?? "lang-purchase-start",
    path: startamdq6JWD1A5RXX_xVU_45Y_45mZlhQg1KIgEL_VdH5nnD5cMeta?.path ?? "/:lang?/purchase/start",
    props: startamdq6JWD1A5RXX_xVU_45Y_45mZlhQg1KIgEL_VdH5nnD5cMeta?.props ?? false,
    meta: startamdq6JWD1A5RXX_xVU_45Y_45mZlhQg1KIgEL_VdH5nnD5cMeta || {},
    alias: startamdq6JWD1A5RXX_xVU_45Y_45mZlhQg1KIgEL_VdH5nnD5cMeta?.alias || [],
    redirect: startamdq6JWD1A5RXX_xVU_45Y_45mZlhQg1KIgEL_VdH5nnD5cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutiUm_0tr4l8qBiTJrbmCagx6BiS0AzRBNoUEE40M4ugQMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutiUm_0tr4l8qBiTJrbmCagx6BiS0AzRBNoUEE40M4ugQMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutiUm_0tr4l8qBiTJrbmCagx6BiS0AzRBNoUEE40M4ugQMeta?.props ?? false,
    meta: checkoutiUm_0tr4l8qBiTJrbmCagx6BiS0AzRBNoUEE40M4ugQMeta || {},
    alias: checkoutiUm_0tr4l8qBiTJrbmCagx6BiS0AzRBNoUEE40M4ugQMeta?.alias || [],
    redirect: checkoutiUm_0tr4l8qBiTJrbmCagx6BiS0AzRBNoUEE40M4ugQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: ko8v1y_fINlinlvZ0FP_45Cz0UNRnfEJFz9dWoK3YK4ud_EMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: ko8v1y_fINlinlvZ0FP_45Cz0UNRnfEJFz9dWoK3YK4ud_EMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: ko8v1y_fINlinlvZ0FP_45Cz0UNRnfEJFz9dWoK3YK4ud_EMeta?.props ?? false,
    meta: ko8v1y_fINlinlvZ0FP_45Cz0UNRnfEJFz9dWoK3YK4ud_EMeta || {},
    alias: ko8v1y_fINlinlvZ0FP_45Cz0UNRnfEJFz9dWoK3YK4ud_EMeta?.alias || [],
    redirect: ko8v1y_fINlinlvZ0FP_45Cz0UNRnfEJFz9dWoK3YK4ud_EMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationBayc2tAZsTqa24WXOxHXNil6ar6zMZL2bs3tfpQfz_45cMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationBayc2tAZsTqa24WXOxHXNil6ar6zMZL2bs3tfpQfz_45cMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationBayc2tAZsTqa24WXOxHXNil6ar6zMZL2bs3tfpQfz_45cMeta?.props ?? false,
    meta: confirmationBayc2tAZsTqa24WXOxHXNil6ar6zMZL2bs3tfpQfz_45cMeta || {},
    alias: confirmationBayc2tAZsTqa24WXOxHXNil6ar6zMZL2bs3tfpQfz_45cMeta?.alias || [],
    redirect: confirmationBayc2tAZsTqa24WXOxHXNil6ar6zMZL2bs3tfpQfz_45cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationzZGgcRyuMXAqV_xjN2GMkMnKZISeUzFtlbOu_L1Z8KYMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationzZGgcRyuMXAqV_xjN2GMkMnKZISeUzFtlbOu_L1Z8KYMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationzZGgcRyuMXAqV_xjN2GMkMnKZISeUzFtlbOu_L1Z8KYMeta?.props ?? false,
    meta: confirmationzZGgcRyuMXAqV_xjN2GMkMnKZISeUzFtlbOu_L1Z8KYMeta || {},
    alias: confirmationzZGgcRyuMXAqV_xjN2GMkMnKZISeUzFtlbOu_L1Z8KYMeta?.alias || [],
    redirect: confirmationzZGgcRyuMXAqV_xjN2GMkMnKZISeUzFtlbOu_L1Z8KYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerU45MEbIsFJbmuwNE4TmMyaDUfx6lMKGGeCU5Emg6QEwMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerU45MEbIsFJbmuwNE4TmMyaDUfx6lMKGGeCU5Emg6QEwMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerU45MEbIsFJbmuwNE4TmMyaDUfx6lMKGGeCU5Emg6QEwMeta?.props ?? false,
    meta: buyerU45MEbIsFJbmuwNE4TmMyaDUfx6lMKGGeCU5Emg6QEwMeta || {},
    alias: buyerU45MEbIsFJbmuwNE4TmMyaDUfx6lMKGGeCU5Emg6QEwMeta?.alias || [],
    redirect: buyerU45MEbIsFJbmuwNE4TmMyaDUfx6lMKGGeCU5Emg6QEwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyer8NBFzRbFsu1_45mAJKKtaSkyEbvK0aLUi99PPvRUaBFY0Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyer8NBFzRbFsu1_45mAJKKtaSkyEbvK0aLUi99PPvRUaBFY0Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyer8NBFzRbFsu1_45mAJKKtaSkyEbvK0aLUi99PPvRUaBFY0Meta?.props ?? false,
    meta: buyer8NBFzRbFsu1_45mAJKKtaSkyEbvK0aLUi99PPvRUaBFY0Meta || {},
    alias: buyer8NBFzRbFsu1_45mAJKKtaSkyEbvK0aLUi99PPvRUaBFY0Meta?.alias || [],
    redirect: buyer8NBFzRbFsu1_45mAJKKtaSkyEbvK0aLUi99PPvRUaBFY0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentk6qOvBpEbP8nnk2yptjrX0tNws0Gssou6ffUengKsPoMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentk6qOvBpEbP8nnk2yptjrX0tNws0Gssou6ffUengKsPoMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentk6qOvBpEbP8nnk2yptjrX0tNws0Gssou6ffUengKsPoMeta?.props ?? false,
    meta: paymentk6qOvBpEbP8nnk2yptjrX0tNws0Gssou6ffUengKsPoMeta || {},
    alias: paymentk6qOvBpEbP8nnk2yptjrX0tNws0Gssou6ffUengKsPoMeta?.alias || [],
    redirect: paymentk6qOvBpEbP8nnk2yptjrX0tNws0Gssou6ffUengKsPoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengetcmNMQgdq5WAHMfWZq9wDyGKWFA8KWZeyw_HQ43lmKIMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengetcmNMQgdq5WAHMfWZq9wDyGKWFA8KWZeyw_HQ43lmKIMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengetcmNMQgdq5WAHMfWZq9wDyGKWFA8KWZeyw_HQ43lmKIMeta?.props ?? false,
    meta: challengetcmNMQgdq5WAHMfWZq9wDyGKWFA8KWZeyw_HQ43lmKIMeta || {},
    alias: challengetcmNMQgdq5WAHMfWZq9wDyGKWFA8KWZeyw_HQ43lmKIMeta?.alias || [],
    redirect: challengetcmNMQgdq5WAHMfWZq9wDyGKWFA8KWZeyw_HQ43lmKIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentaxQNi0iVVTLynteDtiFx3fu1MjZ_458Yvg3D8_456l1tq6IMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentaxQNi0iVVTLynteDtiFx3fu1MjZ_458Yvg3D8_456l1tq6IMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentaxQNi0iVVTLynteDtiFx3fu1MjZ_458Yvg3D8_456l1tq6IMeta?.props ?? false,
    meta: paymentaxQNi0iVVTLynteDtiFx3fu1MjZ_458Yvg3D8_456l1tq6IMeta || {},
    alias: paymentaxQNi0iVVTLynteDtiFx3fu1MjZ_458Yvg3D8_456l1tq6IMeta?.alias || [],
    redirect: paymentaxQNi0iVVTLynteDtiFx3fu1MjZ_458Yvg3D8_456l1tq6IMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.props ?? false,
    meta: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta || {},
    alias: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.alias || [],
    redirect: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.path ?? "/spa-balneario/huelva/",
    props: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.props ?? false,
    meta: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta || {},
    alias: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.alias || [],
    redirect: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.path ?? "/spa-balneario/huelva/ayamonte/",
    props: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.props ?? false,
    meta: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta || {},
    alias: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.alias || [],
    redirect: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.path ?? "/spa-balneario/huelva/cartaya/",
    props: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.props ?? false,
    meta: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta || {},
    alias: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.alias || [],
    redirect: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.path ?? "/spa-balneario/huelva/costa-occidental/",
    props: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.props ?? false,
    meta: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta || {},
    alias: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.alias || [],
    redirect: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.path ?? "/spa-balneario/huelva/isla-cristina/",
    props: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.props ?? false,
    meta: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta || {},
    alias: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.alias || [],
    redirect: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.path ?? "/spa-balneario/huelva/punta-umbria/",
    props: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.props ?? false,
    meta: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta || {},
    alias: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.alias || [],
    redirect: SpaList_46pagevPx94Fb1Ak7N6WNzJm8vNs2D7xVMNppYqhxOkovkS6cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/",
    props: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.props ?? false,
    meta: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta || {},
    alias: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.alias || [],
    redirect: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.path ?? "/spa-balneario/huelva/spa-islantilla/",
    props: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.props ?? false,
    meta: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta || {},
    alias: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.alias || [],
    redirect: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/",
    props: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.props ?? false,
    meta: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta || {},
    alias: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.alias || [],
    redirect: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/",
    props: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.props ?? false,
    meta: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta || {},
    alias: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.alias || [],
    redirect: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/",
    props: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.props ?? false,
    meta: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta || {},
    alias: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.alias || [],
    redirect: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/",
    props: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.props ?? false,
    meta: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta || {},
    alias: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.alias || [],
    redirect: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/",
    props: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.props ?? false,
    meta: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta || {},
    alias: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.alias || [],
    redirect: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/",
    props: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.props ?? false,
    meta: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta || {},
    alias: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.alias || [],
    redirect: SpaDetail_46pages4ldA7SITqocoMHnIZTzoLiJ4CQ9fxCzcwJXbLLb4RcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/ritual-exfoliante-de-tierras-lejanas/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/capricho-de-la-naturaleza/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/senda-de-los-aromas/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/masaje-para-embarazadas/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/brisa-del-amanecer/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/sensuality-and-care-inmersion/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/radiance-vitamina-c/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/despertar-de-los-sentidos/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/masaje-lomi-lomi/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/cuentame-un-cuento/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/masaje-energetico-50/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/reflexologia-podal/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/luz-atlantica/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/armonia-zonal/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/piernas-cansadas/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/circuito-ohtels/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-pareja-circuito-ohtels-servicio-de-te-pareja/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-pareja-circuito-ohtels-masaje-de-25-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-parejas-circuito-ohtels-masaje-de-15-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-de-aromaterapia-25-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-de-aromaterapia-55-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/peeling-corporal-de-coco-hidratacion-completa/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-esencia-naturales-25-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-exclusivity-25-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-de-cerveza-parcial-25/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-de-pindas-herbales-60-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-de-cerveza-completo-55/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-esencias-naturales-55-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-exclusivity-55-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-placeres-del-mediterraneo-circuito/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-placeres-del-mediterraneo/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-relajante-20-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-menores/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-sunset-en-egipto/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/exfoliante-corporal/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/tratamiento-detox/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/reflexologia/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/magicglow/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/facial-personalizado/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/chocolaterapia/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/spa-masaje-25/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/spa-masaje-50/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/spa/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/day-pass/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/day-pass-ninos/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/masaje-deep-tissue-50/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/masaje-deep-tissue-25/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-bienestar/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-romance-de-sol-y-luna/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-placeres-del-mediterraneo-circuito/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/ritual-de-energia-satsanga-50-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-craneo-facial/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/momento-zen/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/ritual-diva-vila-gale/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/tratamiento-facial-de-estrella-infantil/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/manicura-de-diva-infantil/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/ritual-dia-glamuroso/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-del-pirata-infantil/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/cafune-caricias-relajantes-del-pelo-infantil/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-expres/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-de-relajacion-total/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-de-sirena-infantil/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-vila-gale-30-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/circuito-spa-3-horas/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/circuito-spa-con-ritual-satsanga-2-personas/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/circuito-spa-ninos-0-12-anos/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/satsanga-spa-isla-canela/masaje-prenatal-30-min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-relajante-mundo-de-los-aromas/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-relajante/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/ritual-a-cuatro-manos/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/ritual-atardecer-en-donana/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/tratamiento-exotico-atlantico/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/ritual-sensual-care-inmersion/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/ritual-power-to-myself/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/tratamiento-especial-after-sun/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-en-cabina-duo/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-profundo/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-luz-serena/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-armonia-zonal/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-completo-55-min-sala-comun-pareja/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-completo-55-min-sala-comun-1-persona/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-localizado-25min-sala-comun-1-persona/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/masaje-exclusivity-de-cerveza-25min/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-vip-en-sala-privada-1-persona/",
    props: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.props ?? false,
    meta: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta || {},
    alias: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.alias || [],
    redirect: ServiceDetail_46pageVkOXbEajEAgUPi8iD_xNpts_45RuxD5pNo8wBm9fQdhn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-ade10700-1d9f-45be-9187-08bc5876f465/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]